import React from 'react'
import Layout from '../utils/layout'
import Post from '../components/Blog/Post'
import PostAuthor from '../components/Blog/PostAuthor'
import PostsLatest from '../components/Blog/PostsLatest'
import SEO from '../utils/seo'

export default ({ pageContext }) => {
  const { post, data, pathPrefix } = pageContext
  const { posts, postSettings, blogSettings } = data
  const { showLatestPosts, numberOfLatestPosts = 6 } = postSettings
  const { author } = post

  return (
    <Layout data={data} lightMode={true}>
      <SEO {...post.seo} />
      <Post {...post} />
      {author && <PostAuthor {...author} />}
      {showLatestPosts && (
        <PostsLatest
          posts={posts.filter(p => p._id !== post._id).slice(0, numberOfLatestPosts)}
          pathPrefix={pathPrefix}
        />
      )}
    </Layout>
  )
}
