import React from 'react'
import getYouTubeId from 'get-youtube-id'
import BlockContent from '@sanity/block-content-to-react'
import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import styles from './style.module.sass'
import cx from 'classnames'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'

const Post = ({ title, tags, publishedAt, readingTime, author, imageBlock, body }) => {
  const publishDate = date => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' }

    return new Date(date).toLocaleDateString('en-US', options)
  }

  const serializers = {
    marks: {
      link: props => (
        <a href={props.mark.href} target="_blank" rel="noreferrer">
          {props.children}
        </a>
      ),
    },
    types: {
      youtube: ({ node }) => {
        const { url } = node
        const id = getYouTubeId(url)
        return (
          <div className={styles.embedYoutube}>
            <iframe src={`https://www.youtube.com/embed/${id}`} frameBorder="0" allowfullscreen=""></iframe>
          </div>
        )
      },
    },
  }

  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        {tags && tags.length > 0 && (
          <div className={styles.tags}>
            {tags.map(tag => (
              <div key={tag._id} className={styles.tag}>
                {tag.title}
              </div>
            ))}
          </div>
        )}

        <h1 className={styles.title}>{title}</h1>

        <div className={styles.info}>
          {author && author.imageBlock && author.name && (
            <div className={styles.author}>
              <img
                className={styles.authorImage}
                {...srcSetProps(sanityImageUrl(author.imageBlock))}
                alt={author.imageBlock?.caption}
              />
              <span className={styles.authorName}>{author.name}</span>
            </div>
          )}
          {publishedAt && (
            <div
              className={cx(styles.date, [
                {
                  [styles.dateLeft]: !author || !author.imageBlock || !author.name,
                },
              ])}
            >
              {publishDate(publishedAt)}
            </div>
          )}
          {readingTime && <div className={styles.readingTime}>{readingTime} mins read</div>}
        </div>

        {imageBlock && (
          <div className={styles.poster}>
            <img
              {...srcSetProps(
                sanityImageUrl(imageBlock),
                [
                  [800, 800],
                  [480, 480],
                ],
                70,
                1300,
              )}
              alt={imageBlock.caption}
            />
          </div>
        )}

        <BlockContent className={styles.content} blocks={body} serializers={serializers} />
      </Wrapper>
    </Container>
  )
}

export default Post
