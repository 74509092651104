import React, { useRef, useCallback } from 'react'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import cx from 'classnames'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'
import './slider.sass'

const PostsLatest = ({ posts, pathPrefix }) => {
  const slider = useRef()
  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: posts.length >= 3 ? 3 : posts.length,
    initialSlide: 0,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: posts.length >= 2 ? 2 : posts.length,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const next = useCallback(() => {
    slider.current.slickNext()
  }, [slider])

  const prev = useCallback(() => {
    slider.current.slickPrev()
  }, [slider])

  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <div className={styles.top}>
          <Title>Latest Resources</Title>

          <div className={styles.actions}>
            <div onClick={prev} className={styles.leftButton} />
            <div onClick={next} className={styles.rightButton} />
          </div>
        </div>

        <Slider {...sliderSettings} ref={slider} className={cx('blog-latest-posts-slider', styles.slider)}>
          {posts.map(post => (
            <Link className={styles.post} to={`${pathPrefix}/${post.slug.current}`} key={post._id}>
              <img
                className={styles.image}
                {...srcSetProps(
                  sanityImageUrl(post.imageBlock),
                  [
                    [800, 800],
                    [480, 480],
                  ],
                  70,
                  1300,
                )}
                alt={post.imageBlock?.caption}
              />
              {post.readingTime && <span className={styles.readingTime}>{post.readingTime} mins read</span>}
              <span className={styles.title}>{post.title}</span>
              <span className={styles.excerpt}>{prepareParagraph(post.excerpt)}</span>
            </Link>
          ))}
        </Slider>
      </Wrapper>
    </Container>
  )
}

export default PostsLatest
