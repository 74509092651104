import React from 'react'
import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Content from '../../Shared/UI/Content'
import Title from '../../Shared/UI/Title'
import IconFb from '../../../images/author-fb.svg'
import IconLinkedin from '../../../images/author-linkedin.svg'
import IconIn from '../../../images/author-in.svg'
import IconTw from '../../../images/author-tw.svg'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

const PostAuthor = ({ name, bio, imageBlock, facebook, linkedin, instagram, twitter }) => {
  return (
    <div className={styles.fullBackground}>
      <Container classes={styles.outer}>
        <Wrapper classes={styles.wrapper}>
          <Title classes={styles.title}>About {name}</Title>
          <div className={styles.section}>
            <div className={styles.left}>
              <Content classes={styles.content} tag="div">
                {bio &&
                  bio.map((paragraph, index) => (
                    <React.Fragment key={paragraph._key}>
                      <p>{prepareParagraph(paragraph.text)}</p>
                    </React.Fragment>
                  ))}
              </Content>
            </div>
            <div className={styles.right}>
              <img className={styles.image} {...srcSetProps(sanityImageUrl(imageBlock))} alt={imageBlock?.caption} />
              <div className={styles.socials}>
                {facebook && (
                  <a className={styles.socialsIcon} href={facebook} target="_blank">
                    <img src={IconFb} alt="Go to facebook" />
                  </a>
                )}
                {linkedin && (
                  <a className={styles.socialsIcon} href={linkedin} target="_blank">
                    <img src={IconLinkedin} alt="Go to linkedin" />
                  </a>
                )}
                {instagram && (
                  <a className={styles.socialsIcon} href={instagram} target="_blank">
                    <img src={IconIn} alt="Go to instagram" />
                  </a>
                )}
                {twitter && (
                  <a className={styles.socialsIcon} href={twitter} target="_blank">
                    <img src={IconTw} alt="Go to twitter" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </Wrapper>
      </Container>
    </div>
  )
}

export default PostAuthor
